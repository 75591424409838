import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { API_CALL_STATE } from "src/constants";
import { roleTemplatePolicy } from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import {
  ADD_ROLE_TO_STORE,
  CREATE_TEMPLATE_FLOW_RESET,
  CREATE_TEMPLATE_FLOW_STORE_DONE,
  CREATE_TEMPLATE_FLOW_STORE_FAIL,
  CREATE_TEMPLATE_FLOW_STORE_INIT,
  CREATE_TEMPLATE_FLOW_STORE_RESET,
  DELETE_ROLE_IN_STORE,
  DELETE_TEMPLATE_FLOW,
  DELETE_TEMPLATE_ROLE_LINKS,
  ROOM_NAME_IS_VALID,
  SEARCH_TEMPLATE_NAME_CANCEL,
  SEARCH_TEMPLATE_NAME_CANCEL_FUNCTION,
  SEARCH_TEMPLATE_NAME_DONE,
  SEARCH_TEMPLATE_NAME_FAIL,
  SEARCH_TEMPLATE_NAME_INIT,
  SET_APP_DETAILS_IN_STORE,
  SET_IS_ROLE_CONFIG_VALID,
  SET_IS_TEMPLATE_CONFIG_VALID,
  SET_LARGE_ROOM_BOOLEAN,
  SET_ROLE_LAYOUTS_IN_STORE,
  SET_ROOM_NAME,
  SET_SELECTED_ROLE,
  SET_STEP_ANSWERS,
  SET_TEMPLATE_FLOW,
  SET_TEMPLATE_FLOW_ID,
  SET_TEMPLATE_FLOW_ID_AND_TYPE,
  SET_TEMPLATE_REGION,
  SET_TEMPLATE_SUBDOMAIN,
  UPDATE_ROLE_IN_STORE,
  UPDATE_TEMPLATE_IN_STORE,
} from "./actions";
const initialState = {
  id: null as null | number,
  policy: {
    name: "",
    roles: {} as Record<string, roleTemplatePolicy>,
  },
  selectedRole: "",
  templateRoleLinks: {},
  addNewAppStatus: "",
  region: "in",
  largeRoom: false,
  policyType: "",
  subdomain: "",
  appDetails: {},
  isRoleConfigValid: true,
  isTemplateConfigValid: true,
  roomId: "",
  isRoomNameValid: false,
  createdTemplate: {},
  roomName: "",
  roleLayouts: {} as RoleLayouts,
  search: {
    template: { error: "", name: "" },
  },
  checkTemplateNameStatus: "",
  stepAnswers: {},
  checkTemplateNameCancelFunction: () => {},
};
export const createTemplate = handleActions(
  {
    [SET_TEMPLATE_FLOW]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.policy = action.payload.policy;
      newState.policyType = action.payload.policyType;
      newState.subdomain = action.payload.subdomain;
      return { ...newState };
    },
    [SET_TEMPLATE_FLOW_ID_AND_TYPE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.id = action.payload.id;
      newState.policyType = action.payload.policyType;
      return { ...newState };
    },
    [SET_TEMPLATE_FLOW_ID]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.id = action.payload;
      return { ...newState };
    },
    [SET_TEMPLATE_SUBDOMAIN]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.subdomain = action.payload;
      return { ...newState };
    },
    [SET_TEMPLATE_REGION]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.region = action.payload;
      return { ...newState };
    },
    [DELETE_TEMPLATE_FLOW]: state => {
      const newState = { ...state };
      const tempPolicy = { roles: {}, name: "" };
      newState.policy = tempPolicy;
      return { ...newState };
    },
    [UPDATE_TEMPLATE_IN_STORE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.policy = action.payload;
      return { ...newState };
    },
    [SET_LARGE_ROOM_BOOLEAN]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.largeRoom = action.payload;
      return { ...newState };
    },
    [SEARCH_TEMPLATE_NAME_INIT]: state => {
      const newState = { ...state };
      newState.checkTemplateNameStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...newState };
    },
    [SEARCH_TEMPLATE_NAME_FAIL]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.checkTemplateNameStatus = API_CALL_STATE.FAILED;
      newState.search.template.error = action.payload.error;
      return { ...newState };
    },
    [SEARCH_TEMPLATE_NAME_DONE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.checkTemplateNameStatus = API_CALL_STATE.DONE;
      newState.search.template.error = action.payload.error;
      newState.search.template.name = action.payload.name;
      return { ...newState };
    },
    [SEARCH_TEMPLATE_NAME_CANCEL]: state => {
      const newState = { ...state };
      newState.checkTemplateNameStatus = "";
      newState.checkTemplateNameCancelFunction = () => {};
      return { ...newState };
    },
    [SEARCH_TEMPLATE_NAME_CANCEL_FUNCTION]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.checkTemplateNameCancelFunction = action.payload;
      return { ...newState };
    },
    [CREATE_TEMPLATE_FLOW_STORE_INIT]: state => {
      const newState = { ...state };
      newState.addNewAppStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...newState };
    },
    [CREATE_TEMPLATE_FLOW_STORE_FAIL]: state => {
      const newState = { ...state };
      newState.addNewAppStatus = API_CALL_STATE.FAILED;
      newState.roomId = "";
      newState.createdTemplate = {};
      return { ...newState };
    },
    [CREATE_TEMPLATE_FLOW_STORE_DONE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.addNewAppStatus = API_CALL_STATE.DONE;
      newState.templateRoleLinks = action.payload.room_links;
      newState.roomId = action.payload.room_id;
      newState.subdomain = action.payload.subdomain;
      newState.createdTemplate = action.payload;
      return { ...newState };
    },
    [CREATE_TEMPLATE_FLOW_STORE_RESET]: state => {
      return {
        ...state,
        templateRoleLinks: {},
        addNewAppStatus: "",
        createdTemplate: {},
      };
    },
    [SET_SELECTED_ROLE]: (state, action: AnyAction) => {
      return {
        ...state,
        selectedRole: action.payload,
      };
    },
    [SET_ROOM_NAME]: (state, action: AnyAction) => {
      return {
        ...state,
        roomName: action.payload,
      };
    },
    [ROOM_NAME_IS_VALID]: (state, action: AnyAction) => {
      return { ...state, isRoomNameValid: action.payload };
    },
    [SET_IS_ROLE_CONFIG_VALID]: (state, action: AnyAction) => {
      return {
        ...state,
        isRoleConfigValid: action.payload,
      };
    },
    [SET_IS_TEMPLATE_CONFIG_VALID]: (state, action: AnyAction) => {
      return {
        ...state,
        isTemplateConfigValid: action.payload,
      };
    },
    [ADD_ROLE_TO_STORE]: (state, action: AnyAction) => {
      return {
        ...state,
        policy: {
          ...state.policy,
          roles: {
            ...state.policy.roles,
            [action.payload.name]: action.payload,
          },
        },
      };
    },
    [SET_ROLE_LAYOUTS_IN_STORE]: (state, action: AnyAction) => {
      return {
        ...state,
        roleLayouts: action.payload,
      };
    },
    [SET_STEP_ANSWERS]: (state, action: AnyAction) => {
      return {
        ...state,
        stepAnswers: action.payload,
      };
    },
    [DELETE_TEMPLATE_ROLE_LINKS]: state => {
      return {
        ...state,
        templateRoleLinks: {},
      };
    },
    [DELETE_ROLE_IN_STORE]: (state, action: AnyAction) => {
      const roleName = action.payload;
      const roles = JSON.parse(JSON.stringify(state.policy.roles));
      // eslint-disable-next-line
      //@ts-ignore
      delete roles[roleName];
      Object.keys(roles).forEach(role => {
        // eslint-disable-next-line
        //@ts-ignore
        roles[role].subscribeParams.subscribeToRoles = roles?.[
          role
        ]?.subscribeParams?.subscribeToRoles.filter(
          (roleinArray: string) => roleinArray !== roleName
        );
      });
      state.policy.roles = roles;
      return { ...state };
    },
    [CREATE_TEMPLATE_FLOW_RESET]: () => ({ ...initialState }),
    [UPDATE_ROLE_IN_STORE]: (state, action: AnyAction) => {
      return {
        ...state,
        policy: {
          ...state.policy,
          roles: {
            ...state.policy.roles,
            [action.payload.name]: action.payload,
          },
        },
      };
    },
    [SET_APP_DETAILS_IN_STORE]: (state, action: AnyAction) => {
      return {
        ...state,
        appDetails: { ...action.payload },
      };
    },
  },
  initialState
);
