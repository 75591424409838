import axios, { AxiosError } from "axios";
import { cloneDeep, isEmpty } from "lodash";
import { AnyAction, Dispatch } from "redux";
import { fetchTemplatesData } from "src/actions/RolesActions";
import api from "src/api";
import toastr from "src/components/Common/toastr";
import { API_CALL_STATE, templateTypes } from "src/constants";
import { AppAnalytics } from "src/helpers";
import { steps } from "src/modules/onboarding/containers/OnboardingFlowWithLayout";
import { LIVE_STREAM_RTMP_INGEST_OPTIONS } from "src/services/cmsModels/Policy/helpers";
import {
  policyTemplate,
  roleTemplateForPolicy,
} from "src/store/policyTemplate";
import { GenericObject } from "src/types/common";
import { DashboardBackendAxiosError } from "src/types/customTypes";
import { policyType } from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import { currentUser, getAPIURL, signedUpUser } from "src/utils";
import { fetchWorkspaceDetails } from "../actions";
import { createRoomRTMPStreamKeys } from "../rooms/actions";

export const SET_TEMPLATE_FLOW = "SET_TEMPLATE_FLOW";
export const SET_IS_ROLE_CONFIG_VALID = "SET_IS_ROLE_CONFIG_VALID";
export const SET_IS_TEMPLATE_CONFIG_VALID = "SET_IS_TEMPLATE_CONFIG_VALID";
export const SET_SELECTED_ROLE = "SET_SELECTED_ROLE";
export const SET_ROOM_NAME = "SET_ROOM_NAME";
export const SET_STEP_ANSWERS = "SET_STEP_ANSWERS";
export const SET_TEMPLATE_FLOW_ID = "SET_TEMPLATE_FLOW_ID";
export const SET_LARGE_ROOM_BOOLEAN = "SET_LARGE_ROOM_BOOLEAN";
export const SET_TEMPLATE_FLOW_ID_AND_TYPE = "SET_TEMPLATE_FLOW_ID_AND_TYPE";
export const SET_TEMPLATE_SUBDOMAIN = "SET_TEMPLATE_SUBDOMAIN";
export const SET_TEMPLATE_REGION = "SET_TEMPLATE_REGION";
export const DELETE_TEMPLATE_FLOW = "DELETE_TEMPLATE_FLOW";
export const ADD_ROLE_TO_STORE = "ADD_ROLE_TO_STORE";
export const UPDATE_ROLE_IN_STORE = "UPDATE_ROLE_IN_STORE";
export const UPDATE_TEMPLATE_IN_STORE = "UPDATE_TEMPLATE_IN_STORE";
export const DELETE_TEMPLATE_ROLE_LINKS = "DELETE_TEMPLATE_ROLE_LINKS";
export const DELETE_ROLE_IN_STORE = "DELETE_ROLE_IN_STORE";
export const CREATE_TEMPLATE_FLOW_RESET = "CREATE_TEMPLATE_FLOW_RESET";
export const SET_APP_DETAILS_IN_STORE = "SET_APP_DETAILS_IN_STORE";
export const SET_ROLE_LAYOUTS_IN_STORE = "SET_ROLE_LAYOUTS_IN_STORE";
export const CREATE_TEMPLATE_FLOW_STORE_INIT =
  "CREATE_TEMPLATE_FLOW_STORE_INIT";
export const CREATE_TEMPLATE_FLOW_STORE_FAIL =
  "CREATE_TEMPLATE_FLOW_STORE_FAIL";
export const CREATE_TEMPLATE_FLOW_STORE_DONE =
  "CREATE_TEMPLATE_FLOW_STORE_DONE";
export const CREATE_TEMPLATE_FLOW_STORE_RESET =
  "CREATE_TEMPLATE_FLOW_STORE_RESET";

export const ROOM_NAME_IS_VALID = "ROOM_NAME_IS_VALID";

export const SEARCH_TEMPLATE_NAME_INIT = "SEARCH_TEMPLATE_NAME_INIT";
export const SEARCH_TEMPLATE_NAME_FAIL = "SEARCH_TEMPLATE_NAME_FAIL";
export const SEARCH_TEMPLATE_NAME_DONE = "SEARCH_TEMPLATE_NAME_DONE";
export const SEARCH_TEMPLATE_NAME_CANCEL_FUNCTION =
  "SEARCH_TEMPLATE_NAME_CANCEL_FUNCTION";
export const SEARCH_TEMPLATE_NAME_CANCEL = "SEARCH_TEMPLATE_NAME_CANCEL";

export function setTemplateIdInStore(id: number | null) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_TEMPLATE_FLOW_ID, payload: id });
  };
}

export function setTemplateIdAndPTypeStore(id: number | null) {
  return (
    dispatch: Dispatch,
    getState: () => {
      (): any;
      new (): any;
      createApp: { (): any; new (): any; publicTemplates: any };
    }
  ) => {
    const publicTemplates = getState().createApp.publicTemplates;
    const cmsTemplate = publicTemplates.filter(
      (template: any) => template.id === id
    )[0];
    let appType = "CREATEOWN";
    if (!isEmpty(cmsTemplate)) {
      appType = cmsTemplate.attributes.type;
    }
    dispatch({
      type: SET_TEMPLATE_FLOW_ID_AND_TYPE,
      payload: { id: id, policyType: appType },
    });
  };
}

export function setLargeRoomBoolInStore(bool: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_LARGE_ROOM_BOOLEAN,
      payload: bool,
    });
  };
}

export function setSubdomainInStore(subdomain: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_TEMPLATE_SUBDOMAIN,
      payload: subdomain.toLowerCase(),
    });
  };
}

export function setAppDetailsInStore(appDetails: {
  tile_shape?: string;
  metadata?: string;
}) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_APP_DETAILS_IN_STORE,
      payload: appDetails,
    });
  };
}

export function setRoleLayoutsInStore(roleLayouts: RoleLayouts) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_ROLE_LAYOUTS_IN_STORE,
      payload: roleLayouts,
    });
  };
}

export function setTemplateInStore({
  policy,
  checkPolicyName = false,
  isImported = false,
}: {
  policy?: {} | policyType;
  checkPolicyName?: boolean;
  isImported?: boolean;
}) {
  // eslint-disable-next-line complexity
  return (dispatch: Dispatch<AnyAction | any>, getState: any) => {
    const id = getState().createTemplate.id;
    if (id === null && isEmpty(policy) && !isImported) {
      dispatch({
        type: SET_TEMPLATE_FLOW,
        payload: {
          policy: { name: "", roles: {} },
          policyType: "",
          subdomain: "",
        },
      });
      return;
    }
    const subdomain = getState().createTemplate.subdomain;
    const cmsTemplate = getState().createApp.publicTemplates.filter(
      (template: any) => template.id === id
    )[0];
    let appType = "CREATEOWN";
    if (!isEmpty(cmsTemplate) && !isImported) {
      appType = cmsTemplate.attributes.type;
    }
    if ((policy as policyType)?.app_type && isImported) {
      appType = (policy as policyType)?.app_type || "CREATEOWN";
    }

    const date = new Date();
    const mins = `0${date.getMinutes()}`.slice(-2);
    const hours = date.getHours();
    const user = currentUser();
    let cleanedFirstNameOrEmail = (user.first_name || "")
      .toString()
      .replace(/[^a-zA-Z0-9-]/g, "")
      .trim();

    // Necessary for users with first names having non ascii characters
    if (cleanedFirstNameOrEmail === "") {
      cleanedFirstNameOrEmail = user.email
        .split("@")[0]
        .toString()
        .replace(/[^a-zA-Z0-9-]/g, "")
        .trim();
    }

    const generatedSubdomain = `${cleanedFirstNameOrEmail}-${appType.replaceAll(
      "_",
      ""
    )}-${hours.toString().concat(mins)}`;
    dispatch({
      type: SET_TEMPLATE_FLOW,
      payload: {
        policy: policy,
        policyType: appType,
        subdomain: subdomain ? subdomain : generatedSubdomain.toLowerCase(),
      },
    });
    const roles = { ...(policy as policyType)?.roles };
    dispatch(setSelectedRole(Object.keys(roles)?.[0]));
    if (checkPolicyName) {
      dispatch(
        checkTemplateName(getState()?.createTemplate?.policy?.name, false)
      );
    }
  };
}

export function updateTemplateInStore(policy: typeof policyTemplate) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_TEMPLATE_IN_STORE, payload: policy });
  };
}

export function setSelectedRole(roleName: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_SELECTED_ROLE, payload: roleName });
  };
}

export function setIsRoleConfigValid(value: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_IS_ROLE_CONFIG_VALID, payload: value });
  };
}

export function setIsRoomNameValid(value: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({ type: ROOM_NAME_IS_VALID, payload: value });
  };
}

export function setIsTemplateConfigValid(value: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_IS_TEMPLATE_CONFIG_VALID, payload: value });
  };
}

export function deleteTemplateInStore() {
  return (dispatch: Dispatch) => {
    dispatch({ type: DELETE_TEMPLATE_FLOW });
  };
}

export function setRoomNameInStore(name: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_ROOM_NAME, payload: name });
  };
}

export function setStepAnswers(obj: {}) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_STEP_ANSWERS, payload: obj });
  };
}

export function addRoleToStore() {
  return (dispatch: Dispatch, getState: any) => {
    const policy = getState().createTemplate.policy;
    const roleInfo = cloneDeep(roleTemplateForPolicy);
    const roles = policy.roles;
    // find a unique role
    do {
      const randomNumber = Math.floor(1000 + Math.random() * 9000);
      roleInfo.name = `new-role-${randomNumber}`;
    } while (roles[roleInfo.name]);
    roleInfo.subscribeParams.subscribeToRoles = [roleInfo.name.toLowerCase()];
    dispatch({ type: ADD_ROLE_TO_STORE, payload: roleInfo });
    //@ts-ignore
    dispatch(setSelectedRole(roleInfo.name));
  };
}

export function deleteTemplateRoleLinks() {
  return (dispatch: Dispatch) => {
    dispatch({ type: DELETE_TEMPLATE_ROLE_LINKS });
  };
}

export function updateRoleInStore(role: typeof roleTemplateForPolicy) {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_ROLE_IN_STORE, payload: role });
  };
}

export function createTemplateFromStoreV2(isOnboarding = false) {
  // eslint-disable-next-line complexity
  return async (dispatch: Dispatch, getState: any) => {
    const {
      subdomain,
      policy,
      addNewAppStatus,
      policyType,
      id,
      search,
      appDetails,
      roleLayouts,
      largeRoom,
    } = getState().createTemplate;
    const region =
      getState().workspace?.current?.store || getState().userInfo.region;
    const stepAnswers = getState().createTemplate.stepAnswers;
    const { all_templates } = getState().roles;
    if (addNewAppStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }

    if (isOnboarding) {
      try {
        await dispatch(
          //eslint-disable-next-line
          //@ts-ignore
          checkTemplateName(policy.name, true)
        );
      } catch (e) {
        dispatch({ type: CREATE_TEMPLATE_FLOW_STORE_FAIL });
        return;
      }
    }
    const user = currentUser();
    await dispatch({ type: CREATE_TEMPLATE_FLOW_STORE_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("add-new-app/v2"), {
          name: isOnboarding ? search.template.name : policy.name,
          subdomain_name: subdomain,
          app_type: policyType,
          app_name: subdomain + process.env.REACT_APP_VERCEL_BASE_DOMAIN,
          region: region,
          ts_template_id: id,
          role_layouts: { ...roleLayouts },
          roles: policy.roles,
          destinations: policy.destinations,
          recordings: policy?.recordings || [],
          plugins: policy.plugins,
          settings: { ...policy.settings, region: region },
          large_room: largeRoom,
          ...appDetails,
        });
      if (resp.data.success) {
        if (!all_templates.length) {
          //eslint-disable-next-line
          //@ts-ignore
          dispatch(fetchWorkspaceDetails());
        }
        //eslint-disable-next-line
        //@ts-ignore
        dispatch(fetchTemplatesData());
        if (
          policyType === templateTypes.LIVE_STREAM &&
          stepAnswers?.[steps.CONFIGURE_USE_CASE.key]?.streamType ===
            LIVE_STREAM_RTMP_INGEST_OPTIONS.external
        ) {
          //@ts-ignore
          await dispatch(createRoomRTMPStreamKeys(resp.data.room_id));
        }
        dispatch({
          type: CREATE_TEMPLATE_FLOW_STORE_DONE,
          payload: {
            ...resp.data,
            subdomain: resp.data.subdomain.includes(
              process.env.REACT_APP_VERCEL_BASE_DOMAIN
            )
              ? resp.data.subdomain
              : resp.data.subdomain.concat(
                  process.env.REACT_APP_VERCEL_BASE_DOMAIN
                ),
          },
        });

        try {
          AppAnalytics.track("app.deploy.success", {
            form_id: window.location.pathname,
            email: user?.email,
            page: window.location.pathname,
            template_id: resp.data.id,
            template: policyType,
            subdomain: subdomain,
            region: region,
            template_settings: { ...policy.settings, region: region },
          });
        } catch (e) {
          console.error(e);
        }
        if (!signedUpUser()) {
          AppAnalytics.track("template.selection", {
            componentId: "guided.onboarding",
            template: policyType,
          });
        }
        toastr.success(resp.data.msg);
      } else {
        try {
          AppAnalytics.track("app.deploy.failure", {
            form_id: window.location.pathname,
            email: currentUser()?.email,
            page: window.location.pathname,
          });
        } catch (e) {
          console.error(e);
        }

        throw new Error(resp.data.msg);
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: CREATE_TEMPLATE_FLOW_STORE_FAIL });
      toastr.error(message);
    }
  };
}

export function createTemplateFromStore(isOnboarding = false) {
  // eslint-disable-next-line complexity
  return async (dispatch: Dispatch, getState: any) => {
    const {
      subdomain,
      policy,
      addNewAppStatus,
      policyType,
      id,
      search,
      roomName,
    } = getState().createTemplate;
    const { region } = getState().userInfo;
    const { all_templates } = getState().roles;
    if (addNewAppStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }

    if (isOnboarding) {
      try {
        await dispatch(
          //eslint-disable-next-line
          //@ts-ignore
          checkTemplateName(policy.name, true)
        );
      } catch (e) {
        dispatch({ type: CREATE_TEMPLATE_FLOW_STORE_FAIL });
        return;
      }
    }
    const user = currentUser();
    await dispatch({ type: CREATE_TEMPLATE_FLOW_STORE_INIT });
    try {
      const roomObj: GenericObject = {};
      if (isOnboarding) {
        roomObj["room_name"] = roomName;
      }
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("add-new-app"), {
          name: isOnboarding ? search.template.name : policy.name,
          subdomain_name: subdomain,
          app_type: policyType,
          app_name: subdomain + process.env.REACT_APP_VERCEL_BASE_DOMAIN,
          region: region,
          ts_template_id: id,
          roles: policy.roles,
          destinations: policy.destinations,
          settings: { ...policy.settings, region: region },
          ...roomObj,
        });
      if (resp.data.success) {
        if (!all_templates.length) {
          //eslint-disable-next-line
          //@ts-ignore
          dispatch(fetchWorkspaceDetails());
        }
        //eslint-disable-next-line
        //@ts-ignore
        dispatch(fetchTemplatesData());

        dispatch({
          type: CREATE_TEMPLATE_FLOW_STORE_DONE,
          payload: {
            ...resp.data,
            subdomain: subdomain.concat(
              process.env.REACT_APP_VERCEL_BASE_DOMAIN
            ),
          },
        });
        try {
          AppAnalytics.track("app.deploy.success", {
            form_id: window.location.pathname,
            email: user?.email,
            page: window.location.pathname,
            template_id: resp.data.id,
            template: policyType,
            subdomain: subdomain,
            region: region,
            template_settings: { ...policy.settings, region: region },
          });
        } catch (e) {
          console.error(e);
        }
        if (!signedUpUser()) {
          AppAnalytics.track("template.selection", {
            componentId: "guided.onboarding",
            template: policyType,
          });
        }
        toastr.success(resp.data.msg);
      } else {
        try {
          AppAnalytics.track("app.deploy.failure", {
            form_id: window.location.pathname,
            email: currentUser()?.email,
            page: window.location.pathname,
          });
        } catch (e) {
          console.error(e);
        }

        throw new Error(resp.data.msg);
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: CREATE_TEMPLATE_FLOW_STORE_FAIL });
      toastr.error(message);
    }
  };
}

export function checkTemplateName(
  template_name: string,
  isOnboarding: boolean
) {
  return async (dispatch: Dispatch) => {
    const apiInstance = api.service("dashboard");
    dispatch({
      type: SEARCH_TEMPLATE_NAME_CANCEL_FUNCTION,
      payload: apiInstance.cancelRequest,
    });
    dispatch({ type: SEARCH_TEMPLATE_NAME_INIT });

    try {
      const resp = await apiInstance.get(
        getAPIURL("templates/check-name-availability"),
        {
          name: template_name,
          fetch_available: isOnboarding,
        }
      );

      if (resp?.data?.data?.is_available) {
        dispatch({
          type: SEARCH_TEMPLATE_NAME_DONE,
          payload: {
            error: "",
            name: resp.data.data.available_name,
          },
        });
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (e) {
      const result = e as AxiosError;
      const isCancel = axios.isCancel(e);
      console.error(e);
      dispatch({
        type: SEARCH_TEMPLATE_NAME_FAIL,
        payload: {
          error: isCancel
            ? "Template name change check failed"
            : result.message,
          name: template_name,
        },
      });
      if (!isCancel) {
        toastr.error(result.message);
      }
    }
  };
}

export function cancelCheckTemplateName() {
  return (
    dispatch: Dispatch,
    getState: () => {
      createTemplate: {
        checkTemplateNameCancelFunction: any;
      };
    }
  ) => {
    const checkTemplateNameCancelFunction =
      getState()?.createTemplate?.checkTemplateNameCancelFunction;

    checkTemplateNameCancelFunction();
    dispatch({ type: SEARCH_TEMPLATE_NAME_CANCEL });
  };
}

export function deleteRoleInStore(roleName: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: DELETE_ROLE_IN_STORE, payload: roleName });
  };
}

export function resetCreateTemplate() {
  return (dispatch: Dispatch) => {
    dispatch({ type: CREATE_TEMPLATE_FLOW_STORE_RESET });
  };
}

export function resetCreateTemplateReducer() {
  return (dispatch: Dispatch) => {
    dispatch({ type: CREATE_TEMPLATE_FLOW_RESET });
  };
}
