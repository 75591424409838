import toastr from "src/components/Common/toastr";
import { AppAnalytics } from "src/helpers";
import api from "../../../api";
import { API_CALL_STATE } from "../../../constants";
export const VERIFY_AWS_INIT = "VERIFY_AWS_INIT";
export const VERIFY_AWS_DONE = "VERIFY_AWS_DONE";
export const VERIFY_AWS_FAIL = "VERIFY_AWS_FAIL";
export const VERIFY_AWS_RESET = "VERIFY_AWS_RESET";

export function verifyAWSDetails(data, isHipaa) {
  return async (dispatch, getState) => {
    const { awsDetailsStatus } = getState().verifyAWSDetails;
    if (awsDetailsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: VERIFY_AWS_INIT });
    try {
      await api
        .service("dashboard")
        .post("validate-recording-upload-config", { data, hipaa: isHipaa })
        .then(res => {
          if (res.data.success) {
            toastr.success(res.data.msg);
            dispatch({ type: VERIFY_AWS_DONE, payload: res.data });
            AppAnalytics.track("storage.config.verification.success", {
              type: data?.upload?.type,
            });
          } else {
            console.error(res);
            toastr.error(`${res.data.msg}`);
            dispatch({ type: VERIFY_AWS_FAIL });
            AppAnalytics.track("storage.config.verification.failed", {
              type: data?.upload?.type,
            });
          }
        });
    } catch (e) {
      console.error(e);
      toastr.error(`${e.response.data.msg || e.message}`);
      dispatch({ type: VERIFY_AWS_FAIL });
      AppAnalytics.track("storage.config.verification.failed", {
        type: data?.upload?.type,
      });
    }
  };
}
